import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import SEO from "../components/SEO";
import useFirebase from "../../firebase/useFirebase";
import {
  LoginInputWrapper,
  LoginArticle,
  LoginArticleFormsContainer,
  LoginArticleForm,
  LoginArticleNoShow,
  ResetPasswordLink,
  LoginInfo,
} from "../components/Login";
import {
  CustomButton,
  CustomButtonContainer,
  PageSection,
  PageSectionBackdrop,
  PageSectionWrapper,
  TextInput,
} from "../components/common";
import { PageHeaderImageSign } from "../components/common/PageHeaderImage";

const Login = () => {
  const [loginUser, setLoginUser] = useState([
    { userName: "" },
    { userPassword: "" },
  ]);
  const [user, setUser] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const firebase = useFirebase();

  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, [firebase]);

  useEffect(() => {
    if (user) {
      navigate("/account");
    }
  });

  const handleInputValues = (e) => {
    e.persist();
    setLoginUser((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setErrorMessage(false);
    firebase
      .auth()
      .signInWithEmailAndPassword(loginUser.email, loginUser.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("Login page:", user);
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        console.log("errorCode", errorCode);
        console.log("errorMessage", errorMessage);
        if (error) setErrorMessage(true);
      });
  };

  return (
    <>
      <SEO title="Logga in" />
      <PageSectionWrapper>
        <PageSection bgColor="var(--loginBg)">
          <PageSectionBackdrop bgColor="var(--loginBg)">
            <LoginArticleNoShow>
              <h1>
                Använd en enhet med större skärm för att logga in och använda
                inloggat läge
              </h1>
            </LoginArticleNoShow>
            <LoginArticle>
              <LoginArticleFormsContainer>
                <PageHeaderImageSign />
                <h1>Logga in</h1>
                {errorMessage && (
                  <LoginInfo>
                    <p>
                      Användarnamn och lösenord matchar inte, vänligen försök
                      igen.
                    </p>
                    <ResetPasswordLink show bold="true" to="/forgotPassword">
                      Har du glömt ditt lösenord eller användarnamn?
                    </ResetPasswordLink>
                  </LoginInfo>
                )}
                <LoginArticleForm onSubmit={(e) => handleLogin(e)}>
                  <LoginInputWrapper>
                    <label htmlFor="email">E-post:</label>
                    <TextInput
                      type="email"
                      name="email"
                      id="email"
                      placeholder="E-post"
                      onChange={handleInputValues}
                      customWidth="325px"
                    />
                  </LoginInputWrapper>
                  <LoginInputWrapper style={{ marginBottom: "20px" }}>
                    <label htmlFor="password">Lösenord:</label>
                    <TextInput
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Lösenord"
                      onChange={handleInputValues}
                      customWidth="325px"
                    />
                  </LoginInputWrapper>

                  {!errorMessage && (
                    <ResetPasswordLink to="/forgotPassword">
                      Har du glömt ditt lösenord, klicka här
                    </ResetPasswordLink>
                  )}

                  <CustomButtonContainer width="325px">
                    <CustomButton type="submit">Skicka</CustomButton>
                  </CustomButtonContainer>
                </LoginArticleForm>
              </LoginArticleFormsContainer>
            </LoginArticle>
          </PageSectionBackdrop>
        </PageSection>
      </PageSectionWrapper>
    </>
  );
};

export default Login;
